<template>
    <div>
        <card-header title="Edit Transaction" icon="fa-pencil"/>

        <card-body>
            <transaction-form :transaction="transaction"/>
        </card-body>

        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column">
                    <b-button type="is-warning is-outlined" size="is-small" @click="deleteTransaction"><i class="far fa-trash-alt"></i><span class="ml-2" v-if="confirmDelete">Delete Transaction?</span></b-button>
                </div>
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" @click="submit" :disabled="!transaction.description || !transaction.amount || !transaction.created_at"><i class="fas fa-check mr-2"></i>Save Transaction</b-button>
                </div>
            </div>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardBody from "../../TIER/components/CardBody";
    import CardFooter from "../../TIER/components/CardFooter";
    import CardList from "../../TIER/components/CardList";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";
    import {client as http} from "../../http_client";
    import TransactionForm from "./TransactionForm";

    export default {
        components: {TransactionForm, ContainerListItem, SubheaderListItem, CardList, CardFooter, CardBody, CardHeader},
        props: ['card', 'props'],
        data: function () {
            return {
                transaction: {},
                confirmDelete: false,
                dirty: null
            };
        },
        methods: {
            loadTransaction: function () {
                this.$emit('loading', true);
                http.get('/api/transactions/' + this.props.transactionId, {force: true}).then(response => {
                    this.transaction = response.data;
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);
                http.put('/api/transactions/' + this.props.transactionId, this.transaction, {force: true}).then(response => {
                    this.$reloadCard('payment-plan', {planId: this.props.planId});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            deleteTransaction() {
                if (this.confirmDelete) {
                    this.$emit('loading', true);

                    http.delete('/api/transactions/' + this.props.transactionId).then(response => {
                        this.$reloadCard('payment-plan', {planId: this.props.planId});
                        this.$saveAlert(false);
                        this.$closeCard(this.card);
                    }).catch(err => {

                    });
                } else {
                    this.confirmDelete = true;
                    setTimeout(() => {
                        this.confirmDelete = false;
                    }, 3000);
                }
            }
        },
        watch: {
            'props.transactionId': function () {
                this.dirty = null;
                this.loadTransaction();
            },
            'transaction': {
                deep: true,
                handler: function () {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing this transaction? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.loadTransaction();
        }
    };
</script>

<style scoped>

</style>
